import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import '../dist/tailwind.css';
import './main.scss'

import Gumshoe from 'gumshoejs'
import Swiper from 'swiper/bundle';
import initPhotoSwipeFromDOM from './js/photoswipe.js';
import CookiesEuBanner from 'cookies-eu-banner';
// configure Swiper to use modules
import 'htmx.org';
import 'alpinejs';
import '@ryangjchandler/spruce';

var banner = new CookiesEuBanner(function(){
  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    var u = "//stats.371stadtmagazin.de/";
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '4']);
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript'; g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
  })();
});


function initMap(zoom, center){
  map = new google.maps.Map(document.getElementById('map'), {
    zoom: zoom || 11,
    center: center | { lat: 25.684411, lng: -100.317283 }
  });
  var mapReadyEvent = new CustomEvent('map-ready');
  window.dispatchEvent(mapReadyEvent);
}

window.addEventListener('load', () => {

  var HomeCarousel = new Swiper('.home-carousel', {
    spaceBetween: 0,
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    //effect: 'fade',
    speed: 900,
    // fadeEffect: {
    //   crossFade: true
    // },
  });



  if (document.getElementsByClassName('gallery-main').length) {
    var galleryThumbs = {};

    if (document.getElementsByClassName('gallery-thumbs').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
            direction: 'vertical',
          },
        }
      })};
    } else if (document.getElementsByClassName('gallery-thumbs-3x').length) {
      galleryThumbs = {swiper: new Swiper('.gallery-thumbs-3x', {
        spaceBetween: 10,
        slidesPerView: 2,
        freeMode: true,
        navigation: {
          nextEl: '.gallery-thumbs-next',
          prevEl: '.gallery-thumbs-prev',
        },
        loop: true,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        preloadImages: false,
        freeModeSticky: true,
        // Enable lazy loading
        lazy: true,
        breakpoints: {
          // others are 640px, 768px, 1024px, 1280px see tailwind.config.js
          // TODO: import from tailwind
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
            direction: 'vertical',
            centeredSlides: true,
          },
        }
      })};
    }


    var vw = 0;
    var galleryTop, galleryTopArt;

    function switchGalleriesOnBreakpoint(event){
      const breakpoint = 768;
      let new_vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      if ((vw > breakpoint && new_vw < breakpoint) || (vw < breakpoint && new_vw > breakpoint)) {
        vw = new_vw;
        if (vw < breakpoint) {
          if (galleryTopArt) {
            galleryTopArt.destroy();
          }
          galleryTop = new Swiper('.gallery-main', {
            spaceBetween: 0,
            preloadImages: false,
            navigation: {
              nextEl: '.gallery-main-next',
              prevEl: '.gallery-main-prev',
            },
            pagination: {
              el: '.gallery-main-indicator',
              type: 'fraction',
            },
            // Enable lazy loading
            lazy: true,
            thumbs: galleryThumbs,
          });
        } else {
          if (galleryTop) {
            galleryTop.destroy();
          }
          galleryTopArt = new Swiper('.gallery-main', {
            spaceBetween: 0,
            preloadImages: false,
            navigation: {
              nextEl: '.gallery-main-next',
              prevEl: '.gallery-main-prev',
            },
            pagination: {
              el: '.gallery-main-indicator',
              type: 'fraction',
            },
            // Enable lazy loading
            lazy: true,
            thumbs: galleryThumbs,
            effect: 'fade',
            fadeEffect: {
              crossFade: true
            },
          });
        }
      }
    }

    window.addEventListener('resize', switchGalleriesOnBreakpoint);
    switchGalleriesOnBreakpoint();

    initPhotoSwipeFromDOM('.gallery-main');

  }

  initPhotoSwipeFromDOM('.gallery-line');
});
